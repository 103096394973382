@import 'defaults';

:root {
  --item-heading-size: 1.3rem;
}


@mixin item-image {
  transition-property: filter, box-shadow, transform, border-radius;
  transition-duration: var(--transition-duration);
  transition-timing-function: cubic-bezier(.49,.83,.54,1.2);
  width: unset;
  max-width: 200px;
  max-height: 150px;
  border-radius: 4px;
  filter: grayscale(90%) sepia(80%);
}

@mixin item-image-hover {
  transition-duration: var(--transition-duration-long);
  filter: none;
  border-radius: 2px;
  box-shadow: 5px 5px 4px #777;
  transform: scale(1.1);
}