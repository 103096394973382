@import "defaults";

@mixin icon-mixin {
  transition: color var(--transition-duration);
  color: var(--icon-colour);
}

.icon-infinity {
  @include icon-mixin;
  --icon-colour: var(--colour-infinity);
}

.icon-twitter {
  @include icon-mixin;
  --icon-colour: var(--colour-twitter);
}

.icon-linkedin {
  @include icon-mixin;
  --icon-colour: var(--colour-linkedin);
}

.icon-github {
  @include icon-mixin;
  --icon-colour: var(--colour-github);
}


.icon-wordpress {
  @include icon-mixin;
  --icon-colour: var(--colour-wordpress);
}

.icon-writing {
  @include icon-mixin;
  --icon-colour: var(--colour-writing);
}

a {
  i[class^="icon-"] { color: var(--link-colour) }
}

a:hover {
  i[class^="icon-"] { color: var(--icon-colour) }
}