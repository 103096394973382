:root {
  --broad-space: 40px;

  --font-body: 'EB Garamond', serif;
  --font-heading: 'Montserrat', sans-serif;
  --font-colour: #444;
  --font-colour-deemphasised: #888;
  --heading-colour: #111;
  --link-colour: #44a;
  --link-colour-hover: #5985ff;

  // Various colours and so on.
  --colour-twitter: #1da1f2;
  --colour-linkedin: #0077B5;
  --colour-github: #000;
  --colour-infinity: green;
  --colour-wordpress: #00749c;
  --colour-writing: green;


  // transitions
  --transition-duration: 250ms;
  --transition-duration-long: 500ms;
}