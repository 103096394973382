.blog-item > a {
  grid-area: image;
  align-self: center;

  img, iframe {
    @include item-image;
  }

}

.blog-item:hover > a {
  img, iframe {
    @include item-image-hover;
  }
}

.blog-item {

  cursor: pointer;

  @media (min-width: 801px) {
    display: grid;
    grid-template-areas:
            "image          title"
            "description    description";
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 200px 1fr;
    grid-column-gap: var(--broad-space);
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1  { order: 1; }
    img { order: 2; }
    iframe { order: 2; }
    p   { order: 3; }
  }

  h1 {
    color: var(--heading-colour);
    font-size: var(--item-heading-size);
  }

  time {
    color: var(--font-colour-deemphasised);
    white-space: nowrap;
    font-size: 0.8rem;
  }

  .blog-item__description {
    grid-area: description;
    text-align: justify;
    transition: color var(--transition-duration);
    color: var(--font-colour-deemphasised);
  }

  .blog-highlight-item-title {
    grid-area: title;
    align-self: center;
    width: 100%;
  }

  .blog-highlight-item-title.no-image {
    grid-area: image;
  }

  &:hover {
    .blog-item__description {
      transition-duration: var(--transition-duration-long);
      color: var(--font-colour);
    }
  }
}

.blog-item + .blog-item {
  margin-top: calc(var(--broad-space) / 2);
}


