@import 'defaults';
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&family=Montserrat:wght@300;400&display=swap');

:root {
  --dim-border-colour: #ccc;
}

::selection {
  background: lightgreen;
}

body {
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 24px;
  color: var(--font-colour);
}

//--------------------------

@mixin heading-common {
  font-weight: normal;
  font-family: var(--font-heading);
  color: var(--heading-colour);
  margin-left: -3px; // This is to handle the font not aligning well with a follow on paragraph in Gaudy
  line-height: 1.1em;
}

//--------------------------

@mixin small-layout {
  border: none;
  padding: 0;
  margin: 0 0 calc(var(--broad-space)) 0;
  text-align: unset;
  font-family: var(--font-body);
}

.layout-left {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;
  font-size: 0.85rem;
  color: var(--font-colour-deemphasised);
  text-align: right;
  margin: 0 var(--broad-space);
  padding-right: calc(var(--broad-space) / 2);
  border-right: thin solid var(--dim-border-colour);

  @media (max-width: 1300px) {
    @include small-layout;
  }
}

.layout-right {
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;
  font-size: 0.85rem;
  color: var(--font-colour-deemphasised);
  text-align: left;
  margin: 0 calc(var(--broad-space) * 2) 0 var(--broad-space);
  padding-left: calc(var(--broad-space) / 2);
  border-left: thin solid var(--dim-border-colour);

  @media (max-width: 1300px) {
    @include small-layout;
  }
}

.layout-center {
  margin: var(--broad-space) calc(var(--broad-space) * 2);

  @media (max-width: 1300px) {
    @include small-layout;
  }
}

// For text that should become a heading at smaller media sizes.
.layout-heading {
  @media (max-width: 1300px) {
    font-size: larger;
    margin-bottom: calc(var(--broad-space) / 2);
    @include heading-common;
  }
}

.layout-left h1,
.layout-right h1 {
  font-size: 1.2rem;
  @media (min-width: 1300px) {
    margin-left: 0;
  }
}

.layout-left h1 + p,
.layout-right h1 + p {
  margin-top: 0;
}

.layout-left p,
.layout-right p {
  margin: 0;
}

//---------------------------

.title {
  grid-area: title;
  margin: calc(var(--broad-space)) 0;

  h1 {
    font-size: 3em;
    margin-bottom: 0;
  }

  h2 {
    font-weight: lighter;
  }

  p {
    text-align: justify;
    margin-left: calc(var(--broad-space) / 2);
  }

  @media (max-height: 600px) {
    margin: 0;

    h1 { margin: 0 }
  }
}

.title-description {
  grid-area: title-desc;
}

//---------------------------

.hero-fiction {
  grid-area: fiction;
}

.fiction-description {
  grid-area: fiction-desc;
}

.focus-fiction {
  grid-area: focus-fiction;
}

.focus-fiction-description {
  grid-area: ff-desc;
}

.about-me {
  grid-area: about-me;
}

.about-me-description {
  grid-area: am-desc;
}

.article-area {
  grid-area: articles;
}

.article-description {
  grid-area: a-desc;
}

//---------------------------

.footer {
  grid-area: footer;
}

.footer-desc {
  grid-area: footer-desc;
}

footer {
  --link-colour: var(--font-colour-deemphasised);
}

//---------------------------

.dingbat-list {
  list-style-type: none;
  padding: 0 0 0 5px;
  box-sizing: border-box;

  //noinspection CssNoGenericFontName
  li::before {
    transition: color var(--transition-duration);
    font-family: fontello;
    font-size: 0.7em;
    content: '\e800';
    color: var(--font-colour-deemphasised);
    margin-right: 4px;
  }

  &:not(.dingbat-list--no-highlight) {
    li:hover::before {
      transition-duration: var(--transition-duration-long);
      color: green;
    }
  }

  a:not(:hover) {
    color: var(--font-colour);
    text-decoration: none;
  }
}

.dingbat-list--no-highlight {
  --link-colour: var(--font-colour-deemphasised);

  a {
    img {
      transition: filter var(--transition-duration);
      filter: grayscale(100);
    }

    &:hover img {
      filter: none;
    }
  }

}

// For when we want to display lists inline, such as
// one * two * three
.dingbat-list.dingbat-list--inline {
  display: inline-block;
  width: 100%;
  text-align: center;

  li {
    display: inline-block;
  }

  li:first-of-type::before {
    content: '';
  }

  li::before {
    margin-left: 5px;
  }
}

.layout-left .dingbat-list a,
.layout-right .dingbat-list a {
  color: var(--font-colour-deemphasised);
}

@media (max-width: 800px) {
  .dingbat-list.dingbat-list--for-mobile {
    text-align: left;
    li {
      display: block;
    }

    li:first-of-type::before {
      content: '\e800';
      margin-left: 5px;
      margin-right: 4px;
    }
  }
}

//---------------------------

.for-mobile {
  display: none;
}

@media (max-width: 800px) {
  .for-mobile {
    display: inline-block;
  }
}

//---------------------------

.for-desktop {
  display: inline-block;
}

@media (max-width: 800px) {
  .for-desktop {
    display: none;
  }
}

//---------------------------

h1, h2, h3, h4, h5, h6 {
  @include heading-common;
}

//---------------------------

a, a:visited {
  transition: color var(--transition-duration);
  text-decoration: none;
  color: var(--link-colour);
  outline: none;

  &:hover {
    color: var(--link-colour-hover);
  }
}

//---------------------------