@import "defaults";

@keyframes toColour {
  from {
    filter: grayscale(90%) sepia(80%);
  }

  30% {
    filter: grayscale(80%);
  }

  to {
    filter: none;
  }
}


.fiction-item {
  display: flex;
  outline: none;
  text-decoration: none;

  .plyr--audio .plyr__controls {
    padding: 0;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .fiction-item__image {
    align-self: center;
    text-align: right;
    margin-right: calc(var(--broad-space) / 2);

    @media (max-width: 800px) {
      margin-right: 0;
      margin-bottom: calc(var(--broad-space) / 2);
    }

    img {
      @include item-image;

      @media (max-width: 800px) {
        max-width: unset;
        width: 100%;
        text-align: center;
      }
    }
  }

  .fiction-item__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform var(--transition-duration) cubic-bezier(.49,.83,.54,1.2);
    width: 100%;

    header {
      display: flex;
      align-items: baseline;

      h1 {
        flex: 1;
        font-size: var(--item-heading-size);
        color: var(--heading-colour);
        margin: 0;
        min-width: 200px;
      }

      time {
        color: var(--font-colour-deemphasised);
        white-space: nowrap;
        font-size: 0.8rem;
      }
    }

    .fiction-item__description {
      transition: color var(--transition-duration);
      color: var(--font-colour-deemphasised);
      text-align: justify;
    }

    .fiction-item__more {
      display: none;
    }
  }

  &:hover {
    img { @include item-image-hover; }

    .fiction-item__details {
      transition-duration: var(--transition-duration-long);

      @media (min-width: 800px) {
        transform: translateX(20px);
      }
    }

    .fiction-item__description {
      transition-duration: var(--transition-duration-long);
      --font-colour-deemphasised: var(--font-colour);
    }
  }

  @media (min-width: 800px) {
    &:nth-of-type(even) {
      .fiction-item__image {
        order: 2;
        text-align: left;
        margin-left: calc(var(--broad-space) / 2);
        margin-right: unset;
      }

      &:hover {
        .fiction-item__details {
          @media (min-width: 800px) {
            transform: translateX(-20px);
          }
        }
      }
    }
  }
}

// Get some padding between items.
.fiction-item + .fiction-item {
  margin-top: calc(var(--broad-space) * 2);
}

// This is the main fiction item we show on the
// landing page.
.hero-fiction {

  @media (min-width: 800px) {
    margin: calc(var(--broad-space) * 2) 0;
  }

  .fiction-item {
    .fiction-item__image {
      max-height: unset;
      max-width: unset;
      min-width: unset;
      min-height: unset;
    }

    img {
      max-width: 400px;
      max-height: 400px;
      border-radius: 5px;
      filter: none;
    }

    .fiction-item__details {
      justify-content: start;
      margin: calc(var(--broad-space) / 2) 0;

      @media (max-width: 800px) {
        flex-direction: column;
      }

      header {
        flex-direction: column;
        h1 {
          font-size: 2rem;
          margin-bottom: calc(var(--broad-space) / 4);
          margin-left: -3px;
        }

        time {
          margin-right: 5px;
        }
      }


      .fiction-item__description {
        color: var(--font-colour);
        &::first-line { font-variant: small-caps; }
      }

      .fiction-item__more {
        margin-top: 0;
        transition: color var(--transition-duration);
        display: block;
        color: var(--font-colour-deemphasised);
        font-family: var(--font-heading);
        font-weight: lighter;
        font-size: 0.8rem;
      }
    }

    &:hover {
      img {
        border-radius: 3px;
      }

      .fiction-item__description::after {
        color: var(--link-colour-hover);
      }
    }
  }
}


// This is our main fiction page layout
.fiction-content {
  img {
    animation-name: toColour;
    animation-duration: 15s;


    max-width: none;
    width: 120%;
    margin-left: -10%;
    border-radius: 2px;

    @media (max-width: 700px) {
      width: 100vw;
      margin-left: calc(var(--broad-space) * -1);
      margin-right: calc(var(--broad-space) * -1);
    }
  }

  .unsplash {
    margin-left: -10%;
    background: darkgray !important;
    transition: background 250ms;

    @media (max-width: 700px) {
      margin-left: calc(var(--broad-space) * -1);
    }
  }

  .unsplash:hover {
    background: gray !important;
  }


  @media (min-width: 700px) {
    .left {
      float: left;
      max-width: 50%;
      img { max-width: 100% };
      padding-right: calc(var(--broad-space) / 4);
    }

    .right {
      float: right;
      max-width: 50%;
      img {
        max-width: 100%;
        margin-left: 10%;
      }
      .unsplash {
        margin-left: 0;
        margin-right: -10%;
        float: right;
      }
      padding-left: calc(var(--broad-space) / 4);
      padding-right: 0;
    }
  }
}

.fiction {
  h1.post-title {
    margin-bottom: calc(var(--broad-space) / 3);
  }
  .meta {
    margin-bottom: var(--broad-space);

    .dingbat-list {
      --font-colour: var(--font-colour-deemphasised);
      color: var(--font-colour);
      text-align: left;
      padding: 0;
      margin: 0;

      li:first-of-type::before {
        padding: 0;
        margin: 0
      }

      a:not(:hover) {
        i[class^="icon"] {
          color: var(--font-colour);
        }
      }
    }
  }
  
  @media (max-width: 800px) {
    .dingbat-list.dingbat-list--inline {
      li {
        display: block;
      }

      li:first-of-type::before {
        content: '\e800';
        margin-left: 5px;
        margin-right: 4px;
      }
    }
  }


}

.fiction-index {
  @media (min-width: 800px) {
    .hero-fiction {
      margin-left: -100px;
      margin-right: -100px;
    }
  }
}

.fiction-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 70px;
  min-height: 100vh;

  grid-template-areas:
            "header"
            "content"
            "footer";

  header {
    grid-area: header;
    margin: 0 auto;
    width: var(--content-width);
  }

  footer {
    grid-area: footer;
  }

  article {
    grid-area: content;
  }

  .plyr {
    height: unset;
  }

  .fiction-grid {
    grid-area: content;
    display: grid;
    grid-template-columns: 1fr var(--content-width) 1fr;
    grid-template-areas: "left content right";
  }

  .fiction-content {
    grid-area: content;
  }

  .fiction-commentary {
    grid-area: right;
    margin-left: calc(var(--broad-space) * 3);
    margin-right: var(--broad-space);
    padding-left: calc(var(--broad-space) / 2);
    margin-top: calc(var(--broad-space) * 3);


    color: var(--font-colour-deemphasised);
    font-size: 0.9rem;
    border-left: thin solid var(--dim-border-colour);

    h1 {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 1400px) {
  .fiction-page {
    .fiction-grid {
      grid-template-areas: "small-left left small-right"
                          "small-left content small-right"
                          "small-left right small-right";
    }

    .fiction-commentary {
      margin: 0;
      padding: 0;
      border: none;
    }

    .fiction-commentary::before {
      content: '\e800';
      text-align: center;
      font-family: fontello;
      width: 100%;
      display: inline-block;
    }
  }
}

@media (max-width: 800px) {
  .fiction-page {
    header {
      width: 100%;
    }
    .fiction-grid {
      grid-template-columns: minmax(var(--broad-space), 10vw) 1fr minmax(var(--broad-space), 10vw);
    }
    .post-header {
      margin: 0 var(--broad-space);
      width: calc(100% - 2 * var(--broad-space));
    }
  }

}