@import "defaults";

.page > header {
  grid-area: header;
}

.page > footer {
  grid-area: footer;
}

.page > .page-content {
  grid-area: content;
}

.page img {
  width: 100%;
}

.page .post-title {
  margin-bottom: calc(var(--broad-space) * 3);
}